<template>
  <div class="serviceDetail-container">
    <breadcrumb :BreadcrumbData="BreadcrumbData"></breadcrumb>
    <div class="common-screen-container">
      <!-- <div class="common-input-container">
        <span>客服名称/子账号:</span>
        <el-input
          class="common-screen-input"
          placeholder="请输入"
          v-model="params.keyword"
        ></el-input>
      </div>
      <div class="common-input-container">
        <el-date-picker
          v-model="params.datePicker"
          @change="
            () => {
              changeDate();
            }
          "
          type="daterange"
          range-separator="~"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </div>
      <div class="common-input-container">
        <el-radio-group v-model="params.type" @change="changeRadio">
          <el-radio-button :label="0">今天</el-radio-button>
          <el-radio-button :label="1">近7天</el-radio-button>
          <el-radio-button :label="2">近30天</el-radio-button>
          <el-radio-button :label="3">近90天</el-radio-button>
        </el-radio-group>
      </div>
      <el-button
        class="common-screen-btn"
        type="primary"
        @click="() => serveDataDetailList()"
        >查 询</el-button
      >
      <el-button class="common-screen-btn" plain @click="() => resetBtn()"
        >重 置</el-button
      > -->
      <div class="shopName">店铺名称： {{ shopName || "--" }}</div>
    </div>

    <el-button class="common-screen-btn" @click="() => download()"
      >导出数据表格</el-button
    >
    <el-table class="common-table" :data="tableData" @sort-change="tableSort">
      <el-table-column
        prop="serviceName"
        label="客服账号"
        :formatter="tableColumn"
        width="190px"
      ></el-table-column>
      <el-table-column
        prop="subaccountName"
        label="旺旺子账号"
        :formatter="tableColumn"
        width="190px"
      ></el-table-column>
      <el-table-column
        prop="consultationNum"
        label="咨询人数"
        sortable="custom"
        :formatter="tableColumn"
        width="200px"
      ></el-table-column>
      <el-table-column
        prop="receptionNum"
        label="接待人数"
        sortable="custom"
        :formatter="tableColumn"
        width="200px"
      ></el-table-column>
      <el-table-column
        prop="firstTime"
        label="首响时间（s）"
        sortable="custom"
        :formatter="tableColumn"
        width="200px"
      ></el-table-column>
      <el-table-column
        prop="avgTime"
        label="平响时间（s）"
        sortable="custom"
        :formatter="tableColumn"
        width="200px"
      ></el-table-column>
      <el-table-column
        prop="qaRate"
        label="问答比"
        sortable="custom"
        :formatter="tableColumn"
        width="200px"
      ></el-table-column>
      <el-table-column
        prop="replyRate"
        label="回复率"
        sortable="custom"
        :formatter="tableColumn"
        width="200px"
      ></el-table-column>
      <!-- <el-table-column
        prop="shiftIn"
        label="转入人数"
         sortable="custom"
        :formatter="tableColumn"
      ></el-table-column>
       <el-table-column
        prop="shiftOut"
        label="转出人数"
         sortable="custom"
        :formatter="tableColumn"
      ></el-table-column>
       <el-table-column
        prop="noReplyNum"
        label="未回复人数"
         sortable="custom"
        :formatter="tableColumn"
      ></el-table-column>
       <el-table-column
        prop="maxReceptionNum"
        label="最大同时接待"
         sortable="custom"
        :formatter="tableColumn"
      ></el-table-column>
       <el-table-column
        prop="avgReceptionTime"
        label="平均接待时长（s）"
         sortable="custom"
        :formatter="tableColumn"
      ></el-table-column> -->
    </el-table>
    <el-pagination
      @size-change="(e) => pageFun(e, 'pageSize')"
      @current-change="(e) => pageFun(e)"
      style="text-align: center"
      :page-sizes="pagination.pageSizes"
      :page-size="params.pageSize"
      :current-page="params.page"
      layout="total, prev, pager, next,sizes, jumper"
      :total="pagination.total"
    >
    </el-pagination>
  </div>
</template>
<script>
import Breadcrumb from "../../components/common/breadcrumb.vue";
import {
  getLatelyDay,
  tableColumn,
  DateTransform,
  Config,
  toLine
} from "../../utils/index.js";
import { serveDataDetailList } from "../../service/dataInfo.js";
export default {
  components: {
    Breadcrumb,
  },
  data() {
    return {
      tableColumn,
      BreadcrumbData: [
        { title: "首页", isLink: true, url: "/index" },
        { title: "客户", isLink: false },
        {
          title: "店铺服务数据",
          isLink: true,
          url: "/data/customer-service-data",
        },
        { title: "店铺服务数据详情", isLink: false },
      ],
      pagination: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100],
      },
      params: {
        type: 0, // 筛选类型 今天/30天/90天 ...
        datePicker: [new Date(this.$route.query.startTime), new Date(this.$route.query.endTime)], // 时间范围选择-默认当天
        // datePicker: [new Date(), new Date()], // 时间范围选择-默认当天
        page: 1,
        pageSize: 10,
        shopId: this.$route.query.shopId, // 店铺服务数据列表传值
      },
      shopName: this.$route.query.shopName, // 店铺名称
      tableRowId: "", // 客户id用于编辑/查看
      centerDialogVisible: false, // 修改密码员工弹窗
      ruleForm: {
        // 修改密码
        name: "",
      },
      rules: {
        name: [{ required: true, message: "请填写密码", trigger: "change" }],
      },
      drawer: false, // 新增员工弹窗
      roleSelect: "",
      options: [
        // 筛选下拉
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
      ],
      tableData: [
        {
          date: "2016-05-03",
          name: "王小虎",
          province: "上海",
          city: "普陀区",
          address: "上海市普陀区金沙江路 1518 弄",
          zip: 200333,
        },
      ],
    };
  },
  created() {
    this.serveDataDetailList();
  },
  methods: {
    download() {
      // 导出
      let params = { ...this.params };
      params.startDate = DateTransform(params.datePicker[0]);
      params.endDate = DateTransform(params.datePicker[1]);
      delete params.datePicker;
      delete params.page;
      delete params.pageSize;
      if (!params.type) {
        delete params.type;
      }
      let url = Config.api.url + "/dataRecord/serveData/excel/detailExport";
      let paramArr = [];
      for (let item in params) {
        if (params[item]) {
          paramArr.push(item + "=" + params[item]);
        }
      }
      url = url + "?" + paramArr.join("&");
      window.location.href = url;
    },
    resetBtn() {
      this.params = {
        type: 0, // 筛选类型 今天/30天/90天 ...
        datePicker: [new Date(), new Date()], // 时间范围选择-默认当天
      };
      this.serveDataDetailList();
    },
    async serveDataDetailList() {
      // 获取列表数据
      let params = { ...this.params };
      params.startDate = DateTransform(params.datePicker[0]);
      params.endDate = DateTransform(params.datePicker[1]);
      delete params.datePicker;
      if (!params.type) {
        delete params.type;
      }
      if (!params.sortType) {
        delete params.sortField;
        delete params.sortType;
      }
      let resData = (await serveDataDetailList(params)).data;
      this.pagination.total = resData.total;
      this.pagination.pageSize = resData.size;
      this.tableData = resData.list;
    },
    tableSort(e) {
      let params = { ...this.params };
      params.sortField = toLine(e.prop);
      switch (e.order) {
        case "ascending":
          params.sortType = 1;
          break;
        case "descending":
          params.sortType = 2;
          break;
        default:
          params.sortType = null;
      }
      this.params = params;
      this.serveDataDetailList();
    },
    pageFun(e, type) {
      // 分页
      if (type == "pageSize") {
        this.params.pageSize = e;
      } else {
        this.params.page = e;
      }
      this.serveDataDetailList();
    },
    changeRadio(e) {
      // 选择近几天
      let date;
      switch (e) {
        case 0:
          date = 1;
          break;
        case 1:
          date = 7;
          break;
        case 2:
          date = 30;
          break;
        case 3:
          date = 90;
          break;
      }
      this.params.datePicker = getLatelyDay(date);
    },
    changeDate() {
      // 选择时间时清空近几天类型选择
      this.params.type = null;
    },
  },
};
</script>
<style lang="less" scoped>
.serviceDetail-container {
  text-align: left;
  .shopName {
    font-size: 14px;
    color: #000000a6;
  }
}
</style>